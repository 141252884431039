<template>
  <!-- Animated checkbox -->
  <input
    :id="props.name"
    v-model="checked"
    type="checkbox"
    :disabled="props.disabled"
    class="sr-only"
  />

  <label
    :for="props.name"
    class="group my-1 flex cursor-pointer items-center space-x-3"
    :class="{
      'cursor-not-allowed opacity-50': props.disabled
    }"
  >
    <span
      class="group-hover:text-fr-gray-900/70 dark:group-hover:text-fr-gray-100/70 pointer-events-none inline-flex flex-1 select-none items-center gap-2 transition-colors duration-200"
      :class="{
        // Sizes
        'text-xs': props.size === 'xs',
        'text-sm': props.size === 'sm',
        'text-base': props.size === 'md',
        'text-lg': props.size === 'lg',
        'text-xl': props.size === 'xl',
        'text-2xl': props.size === '2xl',
        'text-3xl': props.size === '3xl'
      }"
    >
      <q-icon
        v-if="props.icon"
        :name="props.icon"
        :class="{
          // Size
          'h-4 w-4': props.size === 'xs',
          'h-5 w-5': props.size === 'sm',
          'h-6 w-6': props.size === 'md',
          'h-7 w-7': props.size === 'lg',
          'h-8 w-8': props.size === 'xl',
          'h-9 w-9': props.size === '2xl',
          'h-10 w-10': props.size === '3xl'
        }"
      />
      {{ props.description }}
    </span>
    <span
      class="relative flex items-center justify-center rounded-md border-2 border-solid transition-colors duration-200"
      :class="{
        // Default
        // Primary
        'border-fr-primary-500 group-hover:border-fr-primary-500 group-hover:bg-fr-primary-500-500/20':
          props.color === 'primary',
        'bg-fr-primary-500-500 group-hover:bg-fr-primary-500-500/80':
          props.color === 'primary' && checked,
        // Secondary
        'border-fr-secondary-500 group-hover:border-fr-secondary-500 group-hover:bg-fr-secondary-500/20':
          props.color === 'secondary',
        'bg-fr-secondary-500 group-hover:bg-fr-secondary-500/80':
          props.color === 'secondary' && checked,
        // Danger
        'border-fr-error group-hover:border-fr-error group-hover:bg-fr-error/20':
          props.color === 'danger',
        'bg-fr-error group-hover:bg-fr-error/80':
          props.color === 'danger' && checked,
        // Warning
        'border-fr-warning group-hover:border-fr-warning group-hover:bg-fr-warning/20':
          props.color === 'warning',
        'bg-fr-warning group-hover:bg-fr-warning/80':
          props.color === 'warning' && checked,
        // Success
        'border-fr-success group-hover:border-fr-success group-hover:bg-fr-success/20':
          props.color === 'success',
        'bg-fr-success group-hover:bg-fr-success/80':
          props.color === 'success' && checked,
        // Ghost
        'border-fr-gray-900 group-hover:border-fr-gray-900 group-hover:bg-fr-gray-900/20':
          props.color === 'ghost',
        'bg-fr-gray-900 group-hover:bg-fr-gray-900/80':
          props.color === 'ghost' && checked,

        // Sizes
        'h-4 w-4': props.size === 'xs',
        'h-5 w-5': props.size === 'sm',
        'h-6 w-6': props.size === 'md',
        'h-7 w-7': props.size === 'lg',
        'h-8 w-8': props.size === 'xl',
        'h-9 w-9': props.size === '2xl',
        'h-10 w-10': props.size === '3xl'
      }"
    >
      <svg
        v-if="checked"
        class="pointer-events-none absolute text-white"
        :class="{
          // 'opacity-100': checked,
          // 'opacity-0': !checked,
          // Sizes
          'h-3 w-3': props.size === 'xs',
          'h-4 w-4': props.size === 'sm',
          'h-5 w-5': props.size === 'md',
          'h-6 w-6': props.size === 'lg',
          'h-7 w-7': props.size === 'xl',
          'h-8 w-8': props.size === '2xl',
          'h-9 w-9': props.size === '3xl'
        }"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
          d="M5 13l4 4L19 7"
        >
          <!-- Animate check svg -->
          <animate
            attributeName="stroke-dasharray"
            dur="0.4s"
            :values="`0, 24; 24, 24; 24, 0`"
            repeatCount="1"
          ></animate>
        </path>
      </svg>
    </span>
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string | undefined
    description: string | undefined
    icon?: string | null
    color?:
      | 'primary'
      | 'secondary'
      | 'danger'
      | 'warning'
      | 'success'
      | 'ghost'
      | null
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
    loading?: boolean
    disabled?: boolean
  }>(),
  {
    name: undefined,
    description: undefined,
    icon: null,
    color: 'primary',
    size: 'md',
    loading: false,
    disabled: false
  }
)

const checked = defineModel<boolean>({ default: false })
</script>
