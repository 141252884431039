<template>
  <fr-button v-if="!chipsOnly" variant="outline" icon="fas fa-globe-africa">
    Regions
    <q-tooltip> Region filter </q-tooltip>
    <q-menu
      class="m-0 p-0 shadow-none"
      transition-show="jump-down"
      transition-duration="400"
      :offset="[0, 20]"
    >
      <fr-card>
        <fr-card-body>
          <fr-checkbox
            v-for="(country, index) in countries"
            :key="index"
            v-model="country.selected"
            :name="country.name"
            :description="country.label"
            :icon="`img:/warehouse/${country.name}.svg`"
            size="md"
          />
        </fr-card-body>
      </fr-card>
    </q-menu>
  </fr-button>
  <template v-else>
    <div class="flex flex-row items-center">
      <div class="flex-auto">
        {{ chipsMessage }}
      </div>
      <div class="flex flex-row flex-nowrap">
        <q-chip
          v-for="(country, key) in countries"
          :key="key"
          :color="
            selectedCountries.findIndex((c) => c.name === country.name)
              ? 'secondary'
              : 'primary'
          "
          size="sm"
          >{{ country.name }}</q-chip
        >
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'

withDefaults(
  defineProps<{
    color?: string
    chipsOnly?: boolean
    chipsMessage?: string
  }>(),
  {
    color: 'red',
    chipsOnly: false,
    chipsMessage:
      'Please note that data displayed may be affected by region filter: '
  }
)

const emit = defineEmits(['update:modelValue', 'update-selected'])

const store = useAuthStore()

const selectedCountries = computed({
  get: () => store.countries.filter((country) => country.selected),
  set: (value) => {
    emit('update:modelValue', value)
    emit('update-selected', value)
  }
})

const countries = computed({
  get: () => store.countries,
  set: (value) => (store.countries = value)
})
</script>
